@use "../../globals" as *;

// Submit button
.SubmitButton {
  width: 100px;
  font-size: 16px;
  height: 40px;
  font-weight: bold;
  background-color: $Colour1;
  border: 1px solid black;
  border-radius: 4px;
  color: $Colour4;
  cursor: pointer;
}

// Submit button on hover
.ContactPageContainer button:hover {
  background: $Colour2;
  color: $Colour1;
}

// Disabled submit button
.SubmitButton:disabled {
  cursor: not-allowed;
  background: darkgray;
}

// Disabled button on hover
.SubmitButton[disabled]:hover {
  color: $Colour4;
}