@use "../../globals" as *;

// Page container
.AboutPageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

// Top section
.AboutTop {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 40px;
  width: 90%;
}

// The rows of the top section
.AboutTop > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Description {
  width: 50%;
}

// "Where are we" section
.Map {
  flex-direction: column;
}

// The profile image container div
.ImageContainer {
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

// The profile image
.ImageContainer img {
  //height: 250px;
  min-width: 100px;
  width: 100%;
  height: auto;
  border-radius: 100%;
}

// Bottom section
.AboutBottom {
  width: 80%;
}

// The "Reviews" title
.AboutBottom h2 {
  text-align: center;
}

// The reviews container
.ReviewContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

// All review p elements
.ReviewContainer p {
  margin: 0;
}

// Each review
.ReviewContainer > div {
  width: 400px;
  margin: 0 30px;
}

// The second line of each review, i.e. type and date
.ReviewContainer > div div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// Gap between the type/date line and the review text
.ReviewContainer > div > div {
  margin-bottom: 10px;
}

// Type icon
.ReviewContainer svg {
  margin-right: 10px;
  margin-bottom: 3px;
}

// Review text
.ReviewContainer > div > p {
  font-weight: bold;
}

@media only screen and (max-width: $ResponsiveWidth2) {
  // Top section is now displayed in a column
  .AboutTop {
    flex-direction: column;
  }

  // Decreases image size
  .ImageContainer img {
    width: 300px;
  }

  // Top section now spans entire width
  .AboutTop > div {
    width: 100%;
  }

  // Image is now displayed above the "Who are we" text
  .Description {
    flex-direction: column;
  }
}

@media only screen and (max-width: $ResponsiveWidth) {
  // Displays profile image above text rather than beside it
  .AboutTop {
    flex-direction: column;
  }

  // Centers the image
  .ImageContainer {
    margin-right: 0;
    flex-direction: row;
  }

  // Decreases image size
  .ImageContainer img {
    width: 200px;
  }

  // Move "Reviews" title to left
  .AboutBottom h2 {
    text-align: unset;
  }

  // Shrinks the map
  .MapContainer {
    width: 300px !important;
    height: 300px !important;
  }
}