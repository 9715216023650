@use "../../globals" as *;

// Container for all the highlights
.Highlights {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
  flex-wrap: wrap;
  color: $Colour5;
}

// Container of each highlight
.Highlights > div {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
}

// The heading of each highlight
.Highlights h2 {
  margin: 20px 0 10px;
}

//
.Highlights p {
  font-weight: bold;
}

$IconContainerSize: 70px;
$IconSize: 50px;

// Highlight icons containers
.IconContainer > div {
  width: $IconContainerSize;
  height: $IconContainerSize;
  border-radius: $IconContainerSize;
  background: $Colour4;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.IconContainer {
  border-radius: $IconContainerSize;
  border: 1px solid $Colour2;
}

// Highlight icons
.Highlights svg {
  width: $IconSize;
  height: $IconSize;
  color: $Colour2;
}

// ------------------- Responsive styling -------------------

@media only screen and (max-width: $ResponsiveWidth) {
  .Highlights svg {
    width: 50px;
    height: 50px;
  }

  // Decrease header font size
  .Highlights h2 {
    font-size: large;
  }

  // Extra gap between each highlight
  .Highlights > div {
    margin-bottom: 30px;
  }
}