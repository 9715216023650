// Variables declared in this file can be used in other files that have the following @use statement -
// @use "../../globals" as *;

// Responsive styling -
// As screen width reduces, some styling will be changed at the defined widths below
$ResponsiveWidth: 800px;
$ResponsiveWidth2: 1400px;

// Global colours
$Colour1: #101728;
$Colour2: #cbb58c;
$Colour3: #fd5641;
$Colour4: #f3f1f1;
$Colour5: #a49173;

//$Colour1: #101728;
//$Colour2: #cbb58c;
//$Colour3: #fd5641;
//$Colour4: #f3f1f1;
//$Colour5: #a49173;

// To be used on span or p to highlight text
.Highlighted {
  background-color: $Colour3;
  padding: 3px 0;
  box-shadow: 7px 0 0 $Colour3, -7px 0 0 $Colour3;
}