@use "../../globals" as *;

// Contains both the header and NavBar
.HeaderContainer {
  width: 100%;
  background: #101728;
}

// Header container (no NavBar)
.Header {
  width: 100%;
  height: 120px;
  border-bottom: 1px solid $Colour2;
  justify-content: space-between !important;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 5fr 1fr;
}

// Each item in the header
.SocialIcons, .HeaderCenter, .HeaderCenter > a {
  display: flex;
  align-items: center;
  justify-content: center;
}

// The header link, which is the logo and text
.HeaderCenter a, .HeaderCenter a:link {
  color: $Colour2;
  text-decoration: none;
}

// Each social icon
.SocialIcons a {
  margin-left: 10px;
}

// Logo image
.HeaderCenter img {
  height: 100px;
  width: 100px;
  margin: 0 10px 10px 0;
}

// Logo text
.HeaderCenter h1 {
  font-family: "Zilla Slab", serif;
  font-size: 40px;
  font-weight: normal;
  margin: 0;
  padding-right: 10px;
}

// ------------------- Responsive styling -------------------

@media only screen and (max-width: $ResponsiveWidth) {
  // Header center and the logos are displayed on top of each other rather than beside each other
  .Header {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    height: 170px;
  }

  // Swaps order of logo and header text
  .HeaderCenter > a {
    display: flex;
  }
  .HeaderCenter img {
    order: 2;
  }

  // Order is swapped, social icons are underneath the header center
  .SocialIcons {
    grid-row: 2;
  }

  // Social icons size is reduced
  .SocialIcons svg, .SocialIcons a {
    width: 40px !important;
    height: 40px !important;
  }

}