@use "../../globals" as *;

// NavBar container
.NavBar {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
}

// Every NavBar link's containing div
.NavBar > div {
  display: flex;
  height: 100%;
}

// Every NavBar link
.NavBar a {
  color: $Colour2;
  text-decoration: none;
  font-weight: bold;
  font-size: large;
  padding: 0 30px;
  height: 100%;
  display: flex;
  align-items: center;
}

// NavBar link on hover / when clicked
.NavBar a:hover, .NavBar a:active {
  color: $Colour1;
  background-color: $Colour2;
}

// NavBar link for the current page
.Active {
  color: $Colour1 !important;
  background-color: $Colour2;
}

// ------------------- Responsive styling -------------------

@media only screen and (max-width: $ResponsiveWidth) {
  // Shrink nav bar links
  .NavBar a {
    font-size: medium;
    padding: 0 15px;
  }
}