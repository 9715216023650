@use "./globals" as *;

html, body, #root {
  min-height: 100vh;
  width: 100%;
}

body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  background: $Colour4;
}

p {
  line-height: 30px;
}