@use "../../globals" as *;

// Page container
.ContactPageContainer {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  color: $Colour1;
}

// Sub container (content)
.ContactPageContainer > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

// Description of the page
.Description {
  margin-bottom: 30px;
}

// Form with input/textarea/button elements
.ContactForm {
  width: 100%;
}

// The "cells" in the form
.ContactForm > div {
  margin-bottom: 30px;
}

// Form labels
.ContactForm p {
  margin: 0;
  font-weight: bold;
}

// Adds required asterisk to field labels with this classname
.Required:after {
  content:" *";
  color: red;
  font-size: 20px;
}

// The row with first name and last name
.Names {
  display: flex;
}

.Names div {
  width: 100%;
}

.Names input {
  width: 70%;
}

// First name input
.Names div:first-of-type input {
  margin-right: 30px;
}

// The contact details input
.ContactDetails input {
  width: 50%;
}

// All textareas
.ContactPageContainer textarea {
  width: 100%;
  min-width: 0;
}

// All inputs
.ContactPageContainer input {
  height: 25px;
  min-width: 0;
}

// Submit Button container
.SubmitContainer {
  display: flex;
  justify-content: center;
}

// Error message below the form
.ErrorMsg {
  color: red;
}

// ------------------- Responsive styling -------------------

@media only screen and (max-width: $ResponsiveWidth) {
  // Increases width of the inputs
  .Names {
    flex-direction: column;
  }

  // Add a small gap between the name inputs
  .Names div:first-of-type input {
    margin-bottom: 5px;
  }

  // Increase the relative width of the contact details input
  .ContactDetails input {
    width: 70%;
  }
}