@use "../../globals" as *;

// Container for the whole scroller
.Scroller {
  height: 400px;
  overflow: hidden;
  position: relative;
  display: flex;
  animation: fade-in ease 0.2s;
}

// Scroller animation to fade in on load
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// Container for the image(s)
.ImageContainer {
  height: 100%;
  width: 100%;
  position: absolute;
}

// Scroller (background) image
.ImageContainer img {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

// Edges of the pane, which act as containers for the forward and back arrows
.LeftEdge, .RightEdge {
  position: absolute;
  width: 200px;
  height: 100%;
  z-index: 4;
  cursor: pointer;
  opacity: 0;
}

// Hover styling for the two divs and svgs
.RightEdge:hover, .LeftEdge:hover, .BackArrow:hover + .LeftEdge, .ForwardArrow:hover + .RightEdge {
  transition: 0.2s;
  opacity: 100%;
}

// Left edge div
.LeftEdge {
  left: 0;
  background: linear-gradient(270deg, rgba(0,212,255,0.0004) 39%, rgba(0,0,0,0.5) 100%);
}

// Right edge div
.RightEdge {
  right: 0;
  background: linear-gradient(90deg, rgba(0,212,255,0.0004) 39%, rgba(0,0,0,0.5) 100%);
}

// Forward and back arrow icons
.Scroller svg {
  width: 40px;
  height: 40px;
  color: lightgray;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 5;
}

// Position of the back arrow
.BackArrow {
  left: 20px;
}


// Position of the left arrow
.ForwardArrow {
  right: 20px;
}

// The pane to be displayed next
.NextPane > div {
  z-index: 2;
}

// The currently displayed pane
.CenterPane {
  z-index: 3;
}

// --------- Animations ---------
@mixin Animation {
  position: absolute !important;
}

.SlideOutLeft > div {
  @include Animation;
  animation: slide-out-left 1s ease;
  right: unset;
  left: -100%;
}

@keyframes slide-out-left {
  0% {
    right: unset;
    left: 0;
  }
  100% {
    right: unset;
    left: -100%;
  }
}

.SlideOutRight > div {
  @include Animation;
  animation: slide-out-right 1s ease;
  right: unset;
  left: -100%;
}

@keyframes slide-out-right {
  0% {
    left: unset;
    right: 0;
  }
  100% {
    left: unset;
    right: -100%;
  }
}

// --------- Responsive styling ---------
@media only screen and (max-width: $ResponsiveWidth) {
  // Shrink height of scroller
  .Scroller {
    height: 200px;
  }

  // Hide the edges
  .LeftEdge, .RightEdge {
    display: none;
  }
}