@use "../../globals" as *;

// Page container
.BookingPageContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  color: $Colour1;
}

// ALl inputs
.BookingPageContainer input {
  height: 25px;
}

// Inner page container
.BookingPageContainer > div {
  min-width: 50vw;
  display: flex;
  align-items: center;
  flex-direction: column;
}

// Form container
.BookingForm {
  width: 100%;
}

// Form labels
.BookingForm p {
  margin: 0;
  font-weight: bold;
}

// Each form input
.FormRow1 > div > div, .FormRow2 > div > div {
  margin-bottom: 15px;
}

.FormRow1, .FormRow2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

// First div in top section (name/phone/email)
.FormRow1 > div:first-of-type input {
  width: 50%;
}

// Inputs in the top half of the form
.FormRow1 input {
  width: 80%;
}

// Title for the top half of the form
.FormRow1 h3, .FormRow2 h3 {
  grid-column: 1 / 3;
}

// Form divider line
.Line {
  width: 100%;
  height: 1px;
  background: $Colour1;
  margin: 30px 0;
}

// Radio button containers
.RadioButtons, .RadioButtons > div, .RadioButtons > div > div {
  display: flex;
}

.RadioButtons {
  justify-content: space-between;
}

.RadioButtons > div > div {
  margin-right: 20px;
}

// Radio button labels
.RadioButtons > div p {
  font-weight: normal;
}

// Radio buttons
.RadioButtons input {
  margin-top: 0;
}

// Number inputs
.FormRow2 input[type=number] {
  width: 20%;
}

// Number period dropdown
.FormRow2 select {
  background: none;
  border: none;
  margin-left: 5px;
  cursor: pointer;
}

.VeterinaryPractice {
  display: flex;
  flex-direction: column;
}

// Each question
.FormRow3 > div > div {
  margin-bottom: 40px;
}

// Question textareas
.FormRow3 textarea {
  margin-top: 5px;
  width: 80%;
}

// Question hint text
.Hint {
  font-style: italic;
  font-weight: normal !important;
  font-size: 14px;
}

// Submit Button container
.SubmitContainer {
  display: flex;
  justify-content: center;
}

// Adds required asterisk to field labels with this classname
.Required:after {
  content:" *";
  color: red;
  font-size: 20px;
}

// Error message below the form
.ErrorMsg {
  color: red;
}

// Input field when in an error state
.ErrorField {
  background: #ff97a1;
}

@media only screen and (max-width: $ResponsiveWidth) {
  // Add padding along the edges
  .BookingPageContainer {
    padding: 0 25px;
  }
}